import { gql } from '@apollo/client';

const TOGGLE_ELEVATE_GROUP_MEMBER = gql`
  mutation elevateUserAsProjectAdmin( $userId: Int! $projectId: String! $permission: EMemberPermission! ) {
    membership {
      elevateGroupMember( userId: $userId projectId: $projectId permission: $permission )
    }
  }
`;

export { TOGGLE_ELEVATE_GROUP_MEMBER };
