import { gql } from '@apollo/client';
import { LOGIN_FRAGMENT } from '@netfront/gelada-identity-library';


const SSO_LOGIN = gql`
  query authenticateSso(
    $code: String!,
    $smartCode: String!
    $shouldIncludeOrganisationLogo: Boolean = false
    $shouldIncludeProjectDomain: Boolean = false
    $shouldIncludeProjectLogo: Boolean = false
    $shouldIncludeProjectSettings: Boolean = false
    $shouldIncludeProjectTheme: Boolean = false
    $shouldIncludeUserAvatar: Boolean = false
    $shouldIncludeUserCredential: Boolean = true
    $shouldIncludeUserCustomFields: Boolean = false
    $shouldIncludeUserMembershipsGroup: Boolean = true
    $shouldIncludeUserMembershipsProject: Boolean = true
    $shouldIncludeUserMembershipsUnit: Boolean = false
    $shouldIncludeUserMembershipsUserType: Boolean = false
    $shouldIncludeUserPictures: Boolean = false
    $shouldIncludeUserMemberships: Boolean = false
  ) {
    loginWithSingleSignOn(code: $code, smartCode: $smartCode) {
      ...loginFragment
    }
  }
  ${LOGIN_FRAGMENT}
`;

export { SSO_LOGIN };

