// TODO: Move this to @netfront/gelada-identity-library

import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { DELETE_INVITATION_MUTATION } from './useDeleteInvitation.graphql';
import {
  IDeleteInvitationMutationGraphQLResponse,
  IDeleteInvitationMutationVariables,
  IHandleDeleteInvitationParams,
  IUseDeleteInvitationOptions,
  IUseDeleteInvitation,
} from './useDeleteInvitation.interfaces';

const useDeleteInvitation = (options?: IUseDeleteInvitationOptions): IUseDeleteInvitation => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteInvitationOptions);

  const [executeDeleteInvitation, { loading: isLoading }] = useLoggedGeladaMutation<
    IDeleteInvitationMutationGraphQLResponse,
    IDeleteInvitationMutationVariables
  >({
    mutation: mutation ?? DELETE_INVITATION_MUTATION,
    options: {
      onCompleted: ({ invitation: { deleteInvitation: isCompleted } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteInvitation = async ({ invitationId }: IHandleDeleteInvitationParams) => {
    await executeDeleteInvitation({
      variables: {
        invitationId,
      },
    });
  };

  return {
    handleDeleteInvitation,
    isLoading,
  };
};

export { useDeleteInvitation };
