import { DocumentNode, gql } from '@apollo/client';

const GET_CUSTOM_FIELDS_QUERY: DocumentNode = gql`
  query getCustomFields($projectId: String!, $scope: ECustomFieldScope!) {
    customField {
      getCustomFields(projectId: $projectId, scope: $scope) {
        id
        description
        isRequired
        isSearchable
        name
        displayLocations
        scope
        status

        ... on CustomFieldTextGraphType {
          isPubliclyAvailable
          #regex
        }

        ... on CustomFieldNumberGraphType {
          isPubliclyAvailable
          maxNumber
          minNumber
        }

        ... on CustomFieldDateGraphType {
          isPubliclyAvailable
          rangeFrom
          rangeTo
        }

        ... on CustomFieldBooleanGraphType {
          isPubliclyAvailable
        }

        ... on CustomFieldMultiResponseGraphType {
          isPubliclyAvailable
          allowMultipleResponse
          type
          availableOptions {
            id
            isDefault
            value
            customFieldMultiResponseId
          }
        }
      }
    }
  }
`;

export { GET_CUSTOM_FIELDS_QUERY };
