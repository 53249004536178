import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_GROUP_BY_ID } from './useGetGroupById.graphql';
import {
  IGetGroupByIdQueryGraphQLResponse,
  IGetGroupByIdQueryVariables,
  IHandleGetGroupByIdParams,
  IUseGetGroupById,
  IUseGetGroupByIdOptions,
} from './useGetGroupById.interfaces';

const useGetGroupById = (options?: IUseGetGroupByIdOptions): IUseGetGroupById => {
  const { fetchPolicy, onCompleted, onError } = options ?? ({} as IUseGetGroupByIdOptions);

  const [executeGetGroupById, { loading: isLoading }] = useLoggedGeladaLazyQuery<IGetGroupByIdQueryGraphQLResponse, IGetGroupByIdQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        const {
          group: { getGroup },
        } = data;

        if (!onCompleted) {
          return;
        }

        onCompleted({
          group: getGroup,
        });
      },
      onError,
    },
    query: GET_GROUP_BY_ID,
  });

  const handleGetGroupById = async ({ groupId }: IHandleGetGroupByIdParams) => {
    await executeGetGroupById({
      variables: {
        groupId,
      },
    });
  };

  return {
    handleGetGroupById,
    isLoading,
  };
};

export { useGetGroupById };
