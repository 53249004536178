import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { CREATE_CUSTOM_FIELD_MULTI_RESPONSE } from './useCreateCustomFieldMultiResponse.graphql';
import {
  IUseCreateCustomFieldMultiResponse,
  ICreateCustomFieldMultiResponseMutationGraphQLResponse,
  IUseCreateCustomFieldMultiResponseOptions,
  ICreateCustomFieldMultiResponseMutationVariables,
  IHandleCreateCustomFieldMultiResponseParams,
} from './useCreateCustomFieldMultiResponse.interfaces';

const useCreateCustomFieldMultiResponse = (options?: IUseCreateCustomFieldMultiResponseOptions): IUseCreateCustomFieldMultiResponse => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseCreateCustomFieldMultiResponseOptions);

  const [executeCreateGroupInvitation, { loading: isLoading }] = useLoggedGeladaMutation<
    ICreateCustomFieldMultiResponseMutationGraphQLResponse,
    ICreateCustomFieldMultiResponseMutationVariables
  >({
    mutation: mutation ?? CREATE_CUSTOM_FIELD_MULTI_RESPONSE,
    options: {
      onCompleted: ({
        customField: {
          addCustomFieldMultiResponse: customField,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          customField,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleCreateCustomFieldMultiResponse = async ({
    description,
    isRequired,
    name,
    projectId,
    type,
    scope,
  }: IHandleCreateCustomFieldMultiResponseParams) => {
    await executeCreateGroupInvitation({
      variables: {
        description,
        isRequired,
        name,
        projectId,
        type,
        scope,
      },
    });
  };

  return {
    handleCreateCustomFieldMultiResponse,
    isLoading,
  };
};

export { useCreateCustomFieldMultiResponse };
