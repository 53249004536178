import { gql } from '@apollo/client';

const REGISTER_ACCOUNT_PROJECT = gql`
  mutation registerAccountProject(
    $organisationName: String!
    $organisationKey: String!
    $projectName: String!
    $projectKey: String!
  ) {
    registerFlow {
      createAccountProject(
        organisation: {
          name: $organisationName
          key: $organisationKey
        }
        project: {
          name: $projectName
          key: $projectKey
        }
      ) {
        organisationKey
        projectId
      }
    }
  }
`;

export { REGISTER_ACCOUNT_PROJECT };

