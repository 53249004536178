import { gql } from '@apollo/client';

const GET_GROUP_MEMBERSHIPS = gql`
  query getGroupMemberships($userId: Int!, $projectId: String!, $after: String, $first: Int) {
    membership {
      groupPermissions(projectId: $projectId, after: $after, first: $first, userId: $userId) {
        totalCount
        edges {
          cursor
          node {
            id
            groupId
            unitId
            unit {
              id
              name
              groupId
            }
            group {
              name
            }
            userType {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export { GET_GROUP_MEMBERSHIPS };
