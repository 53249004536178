// TODO: Move this to @netfront/gelada-identity-library

import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { DELETE_ASSET_MUTATION } from './useDeleteAsset.graphql';
import {
  IDeleteAssetMutationGraphQLResponse,
  IDeleteAssetMutationVariables,
  IHandleDeleteAssetParams,
  IUseDeleteAssetOptions,
  IUseDeleteAsset,
} from './useDeleteAsset.interfaces';

const useDeleteAsset = (options?: IUseDeleteAssetOptions): IUseDeleteAsset => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteAssetOptions);

  const [executeDeleteAsset, { loading: isLoading }] = useLoggedGeladaMutation<
  IDeleteAssetMutationGraphQLResponse,
  IDeleteAssetMutationVariables
  >({
    mutation: mutation ?? DELETE_ASSET_MUTATION,
    options: {
      onCompleted: ({ asset: { deleteAsset: isCompleted } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteAsset = async ({ id }: IHandleDeleteAssetParams) => {
    await executeDeleteAsset({
      variables: {
        id,
      },
    });
  };

  return {
    handleDeleteAsset,
    isLoading,
  };
};

export { useDeleteAsset };
