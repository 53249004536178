import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { COMMT_CHANGE } from './useCommitChange.graphql';
import {
  IUseCommitChange,
  ICommitChangeMutationGraphQLResponse,
  IUseCommitChangeOptions,
  ICommitChangeMutationVariables,
} from './useCommitChange.interfaces';

const useCommitChange = (options?: IUseCommitChangeOptions): IUseCommitChange => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseCommitChangeOptions);

  const [executeCreateGroupInvitation, { loading: isLoading }] = useLoggedGeladaMutation<
    ICommitChangeMutationGraphQLResponse,
    ICommitChangeMutationVariables
  >({
    mutation: mutation ?? COMMT_CHANGE,
    options: {
      onCompleted: ({ user: { commitChange } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          result: commitChange,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleCommitChange = async ({ token: changeToken }: ICommitChangeMutationVariables) => {
    await executeCreateGroupInvitation({
      variables: {
        token: changeToken,
      },
    });
  };

  return {
    handleCommitChange,
    isLoading,
  };
};

export { useCommitChange };
