import { gql } from '@apollo/client';

const REGISTER_USER_DETAILS = gql`
  mutation registerUserDetails(
    $firstName: String!
    $lastName: String!
    $password: String!
  ) {
    user {
      updateUser(firstname: $firstName, lastname: $lastName)
      updatePassword(newPassword: $password, currentPassword: "")
    }
  }
`;

export { REGISTER_USER_DETAILS };

