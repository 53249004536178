import { gql } from '@apollo/client';

const ANSWER_GROUP_CUSTOM_FIELDS_MUTATION = gql`
  mutation answerCustomFields($responses: [CustomFieldResponseInputType!]!, $groupId: Int) {
    customFieldResponse {
      answerGroupCustomFields(reponses: $responses, groupId: $groupId)
    }
  }
`;

export { ANSWER_GROUP_CUSTOM_FIELDS_MUTATION };
