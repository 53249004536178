import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { CREATE_CUSTOM_FIELD_DATE } from './useCreateCustomFieldDate.graphql';
import {
  IUseCreateCustomFieldDate,
  ICreateCustomFieldDateMutationGraphQLResponse,
  IUseCreateCustomFieldDateOptions,
  ICreateCustomFieldDateMutationVariables,
  IHandleCreateCustomFieldDateParams,
} from './useCreateCustomFieldDate.interfaces';

const useCreateCustomFieldDate = (options?: IUseCreateCustomFieldDateOptions): IUseCreateCustomFieldDate => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseCreateCustomFieldDateOptions);

  const [executeCreateGroupInvitation, { loading: isLoading }] = useLoggedGeladaMutation<
    ICreateCustomFieldDateMutationGraphQLResponse,
    ICreateCustomFieldDateMutationVariables
  >({
    mutation: mutation ?? CREATE_CUSTOM_FIELD_DATE,
    options: {
      onCompleted: ({
        customField: {
          addCustomFieldDate: customField,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          customField,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleCreateCustomFieldDate = async ({
    description,
    isRequired,
    name,
    projectId,
    rangeFrom,
    rangeTo,
    scope,
  }: IHandleCreateCustomFieldDateParams) => {
    await executeCreateGroupInvitation({
      variables: {
        description,
        isRequired,
        name,
        projectId,
        rangeFrom,
        rangeTo,
        scope,
      },
    });
  };

  return {
    handleCreateCustomFieldDate,
    isLoading,
  };
};

export { useCreateCustomFieldDate };
