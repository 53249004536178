import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { CREATE_USER } from './useCreateUser.graphql';
import {
  IUseCreateUser,
  ICreateUserMutationGraphQLResponse,
  IUseCreateUserOptions,
  ICreateUserMutationVariables,
  IHandleCreateUserParams,
} from './useCreateUser.interfaces';

const useCreateUser = (options?: IUseCreateUserOptions): IUseCreateUser => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseCreateUserOptions);

  const [executeCreateGroupInvitation, { loading: isLoading }] = useLoggedGeladaMutation<
    ICreateUserMutationGraphQLResponse,
    ICreateUserMutationVariables
  >({
    mutation: mutation ?? CREATE_USER,
    options: {
      onCompleted: ({
        user: {
          createUser: { user },
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          user,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleCreateUser = async ({
    email,
    firstname,
    lastname,
    password,
    projectId,
    groupId,
    userTypeId,
    phoneNumber,
    customFieldResponses,
  }: IHandleCreateUserParams) => {
    await executeCreateGroupInvitation({
      variables: {
        user: {
          customFieldResponses,
          email,
          firstname,
          lastname,
          password,
          phoneNumber,
          projectId,
          groupId,
          userTypeId,
        },
      },
    });
  };

  return {
    handleCreateUser,
    isLoading,
  };
};

export { useCreateUser };
