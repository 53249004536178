import { gql } from '@apollo/client';

const UPDATE_PROJECT_MEMBERSHIP_PERMISSION_MUTATION = gql`
  mutation UpdateProjectMembershipPermission($membershipId: Int!, $permission: EMemberPermission!) {
    membership {
      updateProjectMembershipPermission(membershipId: $membershipId, permission: $permission)
    }
  }
`;

export { UPDATE_PROJECT_MEMBERSHIP_PERMISSION_MUTATION };
