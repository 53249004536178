import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { ADD_OPTION_INTO_MULTI_RESPONSE } from './useAddOptionIntoMultiResponse.graphql';
import {
  IUseAddOptionIntoMultiResponse,
  IAddOptionIntoMultiResponseMutationGraphQLResponse,
  IUseAddOptionIntoMultiResponseOptions,
  IAddOptionIntoMultiResponseMutationVariables,
  IHandleAddOptionIntoMultiResponseParams,
} from './useAddOptionIntoMultiResponse.interfaces';

const useAddOptionIntoMultiResponse = (options?: IUseAddOptionIntoMultiResponseOptions): IUseAddOptionIntoMultiResponse => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseAddOptionIntoMultiResponseOptions);

  const [executeCreateGroupInvitation, { loading: isLoading }] = useLoggedGeladaMutation<
    IAddOptionIntoMultiResponseMutationGraphQLResponse,
    IAddOptionIntoMultiResponseMutationVariables
  >({
    mutation: mutation ?? ADD_OPTION_INTO_MULTI_RESPONSE,
    options: {
      onCompleted: ({
        customField: {
          addOptionIntoMultiResponse: isCompleted,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleAddOptionIntoMultiResponse = async ({
    customFieldId,
    option,
  }: IHandleAddOptionIntoMultiResponseParams) => {
    await executeCreateGroupInvitation({
      variables: {
        customFieldId,
        option,
      },
    });
  };

  return {
    handleAddOptionIntoMultiResponse,
    isLoading,
  };
};

export { useAddOptionIntoMultiResponse };
