import { createContext } from 'react';

import { NetfrontProductType } from '@netfront/common-library';
import { IGeladaProject } from '@netfront/gelada-identity-library';

export interface ICachingEntitiesContext {
  canImpersonateUser: boolean;
  dashboardUrl: string | undefined;
  externalUrl?: string;
  hasGroups: boolean;
  hasUnits: boolean;
  isProduct: boolean;
  product?: NetfrontProductType;
  project: IGeladaProject | undefined;
  refreshProjectDetails: () => void;
}

export const CachingEntitiesContext = createContext<ICachingEntitiesContext>({
  project: undefined,
  isProduct: false,
  dashboardUrl: undefined,
  externalUrl: undefined,
  product: 'EKARDO',
  hasGroups: false,
  canImpersonateUser: false,
  hasUnits: false,
  refreshProjectDetails: () => {
    return;
  },
});

// eslint-disable-next-line import/no-default-export
export default CachingEntitiesContext;
