import { gql } from '@apollo/client';

export const CREATE_CUSTOM_FIELD_MULTI_RESPONSE = gql`
  mutation createCustomFieldMultiResponse(
    $projectId: String!,
    $isRequired: Boolean!,
    $name: String!,
    $scope: ECustomFieldScope!,
    $description: String,
    $type: ECustomFieldMultiResponseType!
  ) {
    customField {
      addCustomFieldMultiResponse(
        projectId: $projectId,
        isRequired: $isRequired, 
        name: $name,
        scope: $scope,
        description: $description,
        type: $type
      ) {
        id
      }
    }
  }
`;

