import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_GROUP } from './useGetGroup.graphql';
import {
  IGetGroupQueryGraphQLResponse,
  IGetGroupQueryVariables,
  IHandleGetGroupParams,
  IUseGetGroup,
  IUseGetGroupOptions,
} from './useGetGroup.interfaces';

const useGetGroup = (options?: IUseGetGroupOptions): IUseGetGroup => {
  const { fetchPolicy, onCompleted, onError } = options ?? ({} as IUseGetGroupOptions);

  const [executeGetGroup, { loading: isLoading }] = useLoggedGeladaLazyQuery<IGetGroupQueryGraphQLResponse, IGetGroupQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        const {
          group: { byCode },
        } = data;

        if (!onCompleted) {
          return;
        }

        onCompleted({
          group: byCode,
        });
      },
      onError,
    },
    query: GET_GROUP,
  });

  const handleGetGroup = async ({ code }: IHandleGetGroupParams) => {
    await executeGetGroup({
      variables: {
        code,
      },
    });
  };

  return {
    handleGetGroup,
    isLoading,
  };
};

export { useGetGroup };
