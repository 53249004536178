import { useCustomMutation } from '../useCustomMutation';

import { ENABLE_EKARDO_PROJECT_MUTATION } from './useEnableEkardoProject.graphql';
import {
  IEnableEkardoProjectMutationGraphQLResponse,
  IEnableEkardoProjectMutationVariables,
  IHandleEnableEkardoProjectParams,
  IUseEnableEkardoProjectOptions,
  IUseEnableEkardoProject,
} from './useEnableEkardoProject.interfaces';

const useEnableEkardoProject = (options?: IUseEnableEkardoProjectOptions): IUseEnableEkardoProject => {
  const { mutation, onCompleted, onError, token,  apiName } = options ?? ({} as IUseEnableEkardoProjectOptions);

  const [executeEnableEkardoProject, { loading: isLoading }] = useCustomMutation<
  IEnableEkardoProjectMutationGraphQLResponse,
  IEnableEkardoProjectMutationVariables
  >({
    mutation: mutation ?? ENABLE_EKARDO_PROJECT_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          project: { enableProject: { id } },
        } = data;

        onCompleted({
          id,
        });
      },
      onError,
    },
    token,
    apiName,
  });

  const handleEnableEkardoProject = ({ projectGuid }: IHandleEnableEkardoProjectParams) => {
    void executeEnableEkardoProject({
      variables: {
        projectGuid
      },
    });
  };

  return {
    handleEnableEkardoProject,
    isLoading,
  };
};

export { useEnableEkardoProject };
