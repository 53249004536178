import { ApolloQueryResult } from '@apollo/client';
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_GROUP_MEMBERSHIPS } from './useGetGroupPermissions.graphql';
import {
  IGetGroupPermissionsQueryGraphQLResponse,
  IGetGroupPermissionsQueryVariables,
  IHandleFetchMoreGetGroupPermissionsParams,
  IHandleGetGroupPermissionsParams,
  IUseGetGroupPermissions,
  IUseGetGroupPermissionsOptions,
} from './useGetGroupPermissions.interfaces';

const useGetGroupPermissions = (options?: IUseGetGroupPermissionsOptions): IUseGetGroupPermissions => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetGroupPermissionsOptions);

  const [executeGetGroupPermissions, { fetchMore, loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetGroupPermissionsQueryGraphQLResponse,
    IGetGroupPermissionsQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'no-cache',
      onCompleted: ({ membership: { groupPermissions: membershipConnection } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          membershipConnection,
        });
      },
      onError,
    },
    query: query ?? GET_GROUP_MEMBERSHIPS,
    token,
  });

  const handleFetchMoreGroupPermissions = ({
    after,
    first,
    projectId,
    userId,
  }: IHandleFetchMoreGetGroupPermissionsParams): Promise<ApolloQueryResult<IGetGroupPermissionsQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (
        previousQueryResult,
        {
          fetchMoreResult: {
            membership: { groupPermissions: newlyFetchedMembershipConnection },
          },
        },
      ) => {
        const { edges: newlyFetchedEdges } = newlyFetchedMembershipConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          membership: { groupPermissions: previouslyFetchedMembershipConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedMembershipConnection;

        return {
          ...previousQueryResult,
          membership: {
            ...previouslyFetchedMembershipConnection,
            groupPermissions: {
              ...newlyFetchedMembershipConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        projectId,
        userId,
      },
    });
  };

  const handleGetGroupPermissions = async ({ after, first, userId, projectId }: IHandleGetGroupPermissionsParams) => {
    await executeGetGroupPermissions({
      variables: {
        after,
        first,
        projectId,
        userId,
      },
    });
  };

  return {
    handleFetchMoreGroupPermissions,
    handleGetGroupPermissions,
    isLoading,
  };
};

export { useGetGroupPermissions };
