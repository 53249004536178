import { useEffect, useState } from 'react';

import { getParsedQuery } from '@netfront/common-library';
import { useRouter } from 'next/router';

import { IUseReturnUrlQuerystringValidation } from './useReturnUrlQuerystringValidation.interfaces';

import { ERROR_MESSAGES } from '../../constants';

const useReturnUrlQuerystringValidation = (): IUseReturnUrlQuerystringValidation => {
  const { returnUrlIsMissingWillRedirectToTheRoot } = ERROR_MESSAGES;

  const { asPath } = useRouter();
  const { returnUrl: returnUrlQuerystringParam } = getParsedQuery(asPath);

  const [returnUrl, setReturnUrl] = useState<string>();

  useEffect(() => {
    if (!returnUrlQuerystringParam) {
      console.warn(returnUrlIsMissingWillRedirectToTheRoot);
      return;
    }
    setReturnUrl(String(returnUrlQuerystringParam));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnUrlQuerystringParam]);

  return {
    returnUrl,
  };
};

export { useReturnUrlQuerystringValidation };
