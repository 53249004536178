import { gql } from '@apollo/client';

const GET_INVITATION = gql`
  query getInvitation($token: String!) {
    getInvitation(token: $token) {
      emailUserInvited
      userExists
      invitedUserId
    }
  }
`;

export { GET_INVITATION };
