import { gql } from '@apollo/client';

const GET_GROUP = gql`
  query getGroup($code: String!) {
    group {
      byCode(code: $code) {
        description
        contactEmail
        name
        id
        userCount
        groupType {
          name
        }
        notes {
          message
          subject
          creationDate
        }
        unit {
          id
          name
          groupId
        }
        status
        phoneNumber
        contacts {
          firstName: firstname
          lastName: lastname
          credential {
            email
            phoneNumber
          }
        }
        project {
          id
        }
        website
        smartCodes {
          code
          userType {
            name
          }
        }
        customFields {
          ... on CustomFieldResponseTextGraphType {
            text
          }
          customField {
            name
          }
        }
        address {
          country
          state
          city
          line1
          line2
          postCode
        }
      }
    }
  }
`;

export { GET_GROUP };
