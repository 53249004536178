import { gql } from '@apollo/client';

export const CREATE_CUSTOM_FIELD_NUMBER = gql`
  mutation createCustomFieldNumber(
    $projectId: String!,
    $isRequired: Boolean!,
    $name: String!,
    $scope: ECustomFieldScope!,
    $description: String,
    $maxNumber: Int,
    $minNumber: Int
  ) {
    customField {
      addCustomFieldNumber(
        projectId: $projectId,
        isRequired: $isRequired, 
        name: $name,
        scope: $scope,
        description: $description,
        maxNumber: $maxNumber,
        minNumber: $minNumber
      ) {
        id
      }
    }
  }
`;
