import { gql } from '@apollo/client';

const GROUP_INSIGHTS = gql`
query groupInsights($groupId: Int!, $start: DateTime, $end: DateTime) {
  groupInsights {
    logins(groupId: $groupId, period: { start: $start, end: $end }) {
      userTypeLogins {
        userType
        values {
          value
          date
        }
      }
    }
  }
}

`;

export { GROUP_INSIGHTS };
