import { gql } from '@apollo/client';

export const CREATE_ORGANISATION_INVITATION_MUTATION = gql`
  mutation createOrganisationInvitation($invitation: CreateOrgInvitationInput!) {
    invitation {
      createOrganisationInvitation: organisationInvitation(invitation: $invitation) {
        firstName
        group {
          id
          name
        }
        groupId
        id
        invitationEmailStatus
        invitedUserEmail: emailUserInvited
        invitedUserId
        lastName
        userType {
          id
          name
        }
      }
    }
  }
`;
