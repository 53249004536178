import { gql } from '@apollo/client';

const ANSWER_CUSTOM_FIELDS_MUTATION = gql`
  mutation answerCustomFields($projectId: String!, $responses: [CustomFieldResponseInputType!]!, $userId: Int) {
    customFieldResponse {
      answerCustomFields(projectId: $projectId, reponses: $responses, userId: $userId)
    }
  }
`;

export { ANSWER_CUSTOM_FIELDS_MUTATION };
