import { validateOnCompletedAndOnErrorOptions } from '@netfront/common-library';
import { usePublicGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_GELADA_PROJECT_BY_DOMAIN_QUERY } from './useGetGeladaProjectByDomain.graphql';
import {
  IGetGeladaProjectByDomainQueryGraphQLResponse,
  IGetGeladaProjectByDomainQueryVariables,
  IHandleGetGeladaProjectByDomainParams,
  IUseGetGeladaProjectByDomain,
  IUseGetGeladaProjectByDomainOptions,
} from './useGetGeladaProjectByDomain.interfaces';

const useGetGeladaProjectByDomain = (options?: IUseGetGeladaProjectByDomainOptions): IUseGetGeladaProjectByDomain => {
  const { fetchPolicy = 'cache-and-network', onCompleted, onCompletedAsync, onError, onErrorAsync } = options ?? {};

  validateOnCompletedAndOnErrorOptions({
    onCompleted,
    onCompletedAsync,
    onError,
    onErrorAsync,
  });

  const [executeGetGeladaProjectByDomain, { loading: isLoading }] = usePublicGeladaLazyQuery<
  IGetGeladaProjectByDomainQueryGraphQLResponse,
  IGetGeladaProjectByDomainQueryVariables
  >({
    options: {
      fetchPolicy,
      onCompleted: ({ getGeladaProjectByDomain: geladaProject }) => {
        if (onCompleted) {
          onCompleted({
            geladaProject,
          });
        }

        if (onCompletedAsync) {
          void onCompletedAsync({
            geladaProject,
          });
        }
      },
      onError: (error) => {
        if (onError) {
          onError(error);
        }

        if (onErrorAsync) {
          void onErrorAsync(error);
        }
      },
    },
    query: GET_GELADA_PROJECT_BY_DOMAIN_QUERY,
  });

  const handleGetGeladaProjectByDomain = async ({ domain }: IHandleGetGeladaProjectByDomainParams) => {
    await executeGetGeladaProjectByDomain({
      variables: {
        domain,
      },
    });
  };

  return {
    handleGetGeladaProjectByDomain,
    isLoading,
  };
};

export { useGetGeladaProjectByDomain };
