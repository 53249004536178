import { useEffect, useState } from 'react';

const useGetRootHost = () => {
  const [rootHost, setRootHost] = useState<string>();

  useEffect(() => {
    setRootHost(window.location.host.split('.').slice(-2).join('.'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    rootHost,
  };
};

export { useGetRootHost };
