import { createContext } from 'react';

import { IUserContext } from './UserContext.interfaces';

export const UserContext = createContext<IUserContext>({
  user: undefined,
  userId: undefined,
  email: undefined,
  firstName: undefined,
  fullName: undefined,
  lastName: undefined,
  phoneNumber: undefined,
  profileImageUrl: undefined,
  setProfileImageUrl: () => { return },
  setStoredUser: () => { return },
  getUser: () => {return},
});

// eslint-disable-next-line import/no-default-export
export default UserContext;
