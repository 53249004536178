import { useCustomMutation } from '../useCustomMutation';

import { ENABLE_PROJECT_MUTATION } from './useEnableProject.graphql';
import {
  IEnableProjectMutationGraphQLResponse,
  IEnableProjectMutationVariables,
  IHandleEnableProjectParams,
  IUseEnableProjectOptions,
  IUseEnableProject,
} from './useEnableProject.interfaces';

const useEnableProject = (options?: IUseEnableProjectOptions): IUseEnableProject => {
  const { onCompleted, onError, token,  apiName,} = options ?? ({} as IUseEnableProjectOptions);

  const [executeEnableProject, { loading: isLoading }] = useCustomMutation<
  IEnableProjectMutationGraphQLResponse,
  IEnableProjectMutationVariables
  >({
    mutation: ENABLE_PROJECT_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          project: { activate: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
    apiName,
  });

  const handleEnableProject = ({ id, name }: IHandleEnableProjectParams) => {
    void executeEnableProject({
      variables: {
        id,
        name,
      },
    });
  };

  return {
    handleEnableProject,
    isLoading,
  };
};

export { useEnableProject };
