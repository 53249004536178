import { gql } from '@apollo/client';

const ENABLE_PROJECT_MUTATION = gql`
  mutation enableProject($id: String!, $name: String!) {
    project {
      activate(id: $id, name: $name)
    }
  }
`;

export { ENABLE_PROJECT_MUTATION };
