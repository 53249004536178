import { gql } from '@apollo/client';

const GET_CUSTOM_FIELD_INVITATION_ANSWERS_QUERY = gql`
  query getCustomFieldInvitationAnswers($id: Int!) {
    customField {
      getCustomFieldInvitationAnswers(id: $id) {
        customFieldId
        ... on CustomFieldResponseDateGraphType {
          dateTime
          __typename
        }
        ... on CustomFieldResponseMultiResponseGraphType {
          optionId
          __typename
        }
        ... on CustomFieldResponseNumberGraphType {
          number
          __typename
        }
        ... on CustomFieldResponseTextGraphType {
          text
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export { GET_CUSTOM_FIELD_INVITATION_ANSWERS_QUERY };
