import { validateOnCompletedAndOnErrorOptions } from '@netfront/common-library';
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_CUSTOM_FIELD_INVITATION_ANSWERS_QUERY } from './useGetCustomFieldInvitationAnswers.graphql';
import {
  IGetCustomFieldInvitationAnswersQueryGraphQLResponse,
  IGetCustomFieldInvitationAnswersQueryVariables,
  IHandleGetCustomFieldInvitationAnswersParams,
  IUseGetCustomFieldInvitationAnswers,
  IUseGetCustomFieldInvitationAnswersOptions,
} from './useGetCustomFieldInvitationAnswers.interfaces';

const useGetCustomFieldInvitationAnswers = (options?: IUseGetCustomFieldInvitationAnswersOptions): IUseGetCustomFieldInvitationAnswers => {
  const { fetchPolicy = 'cache-and-network', onCompleted, onCompletedAsync, onError, onErrorAsync, query, token } = options ?? {};

  validateOnCompletedAndOnErrorOptions({
    onCompleted,
    onCompletedAsync,
    onError,
    onErrorAsync,
  });

  const [executeGetCustomFieldInvitationAnswers, { loading: isLoading }] = useLoggedGeladaLazyQuery<
  IGetCustomFieldInvitationAnswersQueryGraphQLResponse,
  IGetCustomFieldInvitationAnswersQueryVariables
  >({
    options: {
      fetchPolicy,
      onCompleted: ({ customField: { getCustomFieldInvitationAnswers: customFields } }) => {
        if (onCompleted) {
          onCompleted({
            customFields,
          });
        }

        if (onCompletedAsync) {
          void onCompletedAsync({
            customFields,
          });
        }
      },
      onError: (error) => {
        if (onError) {
          onError(error);
        }

        if (onErrorAsync) {
          void onErrorAsync(error);
        }
      },
    },
    query: query ?? GET_CUSTOM_FIELD_INVITATION_ANSWERS_QUERY,
    token,
  });

  const handleGetCustomFieldInvitationAnswers = async ({ id }: IHandleGetCustomFieldInvitationAnswersParams) => {
    await executeGetCustomFieldInvitationAnswers({
      variables: {
        id
      },
    });
  };

  return {
    handleGetCustomFieldInvitationAnswers,
    isLoading,
  };
};

export { useGetCustomFieldInvitationAnswers };
