import { gql } from '@apollo/client';

const GET_PAGINATED_PROJECT_MEMBERS = gql`
  query getPaginatedProjectMembers($after: String, $filter: String, $first: Int, $projectId: String!, $status: EUserStatus) {
    membership {
      getPaginatedProjectMembers(after: $after, filter: $filter, first: $first, projectId: $projectId, status: $status) {
        edges {
          cursor
          node {
            created
            group {
              name
            }
            groupId
            id
            isProjectAdmin
            permission: permision
            user {
              communityName
              created
              credential {
                email
                phoneNumber
                services
              }
              customFields {
                customField {
                  description
                  name
                }
              }
              firstName: firstname
              id
              lastName: lastname
              status
            }
            userId
            userType {
              id
              name
            }
          }
        }
        totalCount
      }
    }
  }
`;

export { GET_PAGINATED_PROJECT_MEMBERS };
