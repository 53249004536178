import { gql } from '@apollo/client';

export const RESEND_INVITATION_MUTATION = gql`
  mutation resendInvitation($invitationId: Int!) {
    invitation {
      resendInvitation(invitationId: $invitationId) {
        emailUserInvited
        id
        invitationEmailStatus
        organisationId
        tokenId
      }
    }
  }
`;
