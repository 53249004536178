// TODO: Move this to @netfront/gelada-identity-library

import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { UPDATE_GROUP_MEMBERSHIP } from './useUpdateGroupMembership.graphql';
import {
  IUpdateGroupMembershipMutationGraphQLResponse,
  IUpdateGroupMembershipMutationVariables,
  IHandleUpdateGroupMembershipParams,
  IUseUpdateGroupMembershipOptions,
  IUseUpdateGroupMembership,
} from './useUpdateGroupMembership.interfaces';

const useUpdateGroupMembership = (options?: IUseUpdateGroupMembershipOptions): IUseUpdateGroupMembership => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateGroupMembershipOptions);

  const [executeUpdateGroupMembership, { loading: isLoading }] = useLoggedGeladaMutation<
    IUpdateGroupMembershipMutationGraphQLResponse,
    IUpdateGroupMembershipMutationVariables
  >({
    mutation: mutation ?? UPDATE_GROUP_MEMBERSHIP,
    options: {
      onCompleted: ({ membership: { updateGroupMembership } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          membership: updateGroupMembership,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateGroupMembership = async ({ request }: IHandleUpdateGroupMembershipParams) => {
    await executeUpdateGroupMembership({
      variables: {
        request,
      },
    });
  };

  return {
    handleUpdateGroupMembership,
    isLoading,
  };
};

export { useUpdateGroupMembership };
