import { useLoggedGeladaMutation, usePublicGeladaMutation } from '@netfront/gelada-identity-library';

import { ACCEPT_INVITATION_WITH_LOGIN_DETAILS } from './useAcceptInvitationWithLoginDetails.graphql';
import {
  IAcceptInvitationWithLoginDetailsMutationGraphQLResponse,
  IAcceptInvitationWithLoginDetailsMutationVariables,
  IHandleAcceptInvitationWithLoginDetailsParams,
  IUseAcceptInvitationWithLoginDetailsOptions,
  IUseAcceptInvitationWithLoginDetails,
} from './useAcceptInvitationWithLoginDetails.interfaces';

const useAcceptInvitationWithLoginDetails = (
  options?: IUseAcceptInvitationWithLoginDetailsOptions,
): IUseAcceptInvitationWithLoginDetails => {
  const { onCompleted, onError } = options ?? ({} as IUseAcceptInvitationWithLoginDetailsOptions);

  const [executeAcceptInvitationWithLoginDetails, { loading: isLoading }] = usePublicGeladaMutation<
    IAcceptInvitationWithLoginDetailsMutationGraphQLResponse,
    IAcceptInvitationWithLoginDetailsMutationVariables
  >({
    mutation: ACCEPT_INVITATION_WITH_LOGIN_DETAILS,
    options: {
      onCompleted: () => {
        if (!onCompleted) {
          return;
        }

        onCompleted({ isCompleted: true });
      },
      onError,
    }
  });

  const handleAcceptInvitationWithLoginDetails = async ({ email, password, token }: IHandleAcceptInvitationWithLoginDetailsParams) => {
    await executeAcceptInvitationWithLoginDetails({
      variables: {
        email,
        password,
        token,
      },
    });
  };

  return {
    handleAcceptInvitationWithLoginDetails,
    isLoading,
  };
};

export { useAcceptInvitationWithLoginDetails };
