import { gql } from '@apollo/client';

const ACTIVATE_PRODUCT = gql`
  mutation activateProduct($projectId: String!, $name: String!, $organisationId: Int!, $product: EProduct!) {
    projects {
      activateProduct(input: { product: $product, projectId: $projectId, organisationId: $organisationId, name: $name }) {
        result {
          result
        }
      }
    }
  }
`;

export { ACTIVATE_PRODUCT };
