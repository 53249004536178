import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation ($user: CreateUserInputGraphType!) {
    user {
      createUser(input: $user) {
        user {
          id
          firstname
          lastname
          status
        }
      }
    }
  }
`;
