import { gql } from '@apollo/client';

export const CREATE_CUSTOM_FIELD_TEXT = gql`
  mutation createCustomFieldText(
    $projectId: String!,
    $isRequired: Boolean!,
    $name: String!,
    $scope: ECustomFieldScope!,
    $description: String,
    $regex: String
  ) {
    customField {
      addCustomFieldText(
        projectId: $projectId,
        isRequired: $isRequired, 
        name: $name,
        scope: $scope,
        description: $description,
        regex: $regex
      ) {
        id
      }
    }
  }
`;

