import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { UPDATE_NOTE } from './useUpdateNote.graphql';
import {
  IUseUpdateNote,
  IUpdateNoteMutationGraphQLResponse,
  IUseUpdateNoteOptions,
  IUpdateNoteVariables,
  IHandleUpdateNoteParams,
} from './useUpdateNote.interfaces';

const useUpdateNote = (options?: IUseUpdateNoteOptions): IUseUpdateNote => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseUpdateNoteOptions);

  const [executeUpdateNote, { loading: isLoading }] = useLoggedGeladaMutation<
  IUpdateNoteMutationGraphQLResponse,
  IUpdateNoteVariables
  >({
    mutation: mutation ?? UPDATE_NOTE,
    options: {
      onCompleted: ({
        note: {
          editNote: note,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          note,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleUpdateNote = async ({
    attachments,
    message,
    subject,
    noteId,
  }: IHandleUpdateNoteParams) => {
    await executeUpdateNote({
      variables: {
        attachments,
        message,
        noteId,
        subject,
      },
    });
  };

  return {
    handleUpdateNote,
    isLoading,
  };
};

export { useUpdateNote };
