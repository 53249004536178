import { gql } from '@apollo/client';

export const ADD_OPTION_INTO_MULTI_RESPONSE = gql`
  mutation addOptionIntoMultiResponse(
    $customFieldId: Int!,
    $option: String!
  ) {
    customField {
      addOptionIntoMultiResponse(
        customFieldId: $customFieldId,
        option: $option
      )
    }
  }
`;
