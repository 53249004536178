
import { ACTIVATE_PRODUCT } from './useEnableProduct.graphql';
import {
  IEnableProductMutationGraphQLResponse,
  IEnableProductMutationVariables,
  IHandleEnableProductParams,
  IUseEnableProductOptions,
  IUseEnableProduct,
} from './useEnableProduct.interfaces';

import { useCustomMutation } from '../../useCustomMutation';

const useEnableProduct = (options?: IUseEnableProductOptions): IUseEnableProduct => {
  const { onCompleted, onError, token,} = options ?? ({} as IUseEnableProductOptions);

  const [executeEnableProduct, { loading: isLoading }] = useCustomMutation<
  IEnableProductMutationGraphQLResponse,
  IEnableProductMutationVariables
  >({
    mutation: ACTIVATE_PRODUCT,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          projects: {
            activateProduct: {
              result: { result: isCompleted },
            },
          },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
    apiName: 'muriqui',
  });

  const handleEnableProduct = ({ name, organisationId, product, projectId }: IHandleEnableProductParams) => {
    void executeEnableProduct({
      variables: {
        name,
        organisationId,
        product,
        projectId,
      },
    });
  };

  return {
    handleEnableProduct,
    isLoading,
  };
};

export { useEnableProduct };
