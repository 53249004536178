import { gql } from '@apollo/client';

export const GET_PAGINATED_ORGANISATION_INVITATIONS_MUTATION = gql`
  query getOrganisationInvitations($after: String, $filter: String, $first: Int, $organisationId: Int!) {
    invitation {
      getOrganisationInvitations(after: $after, filter: $filter, first: $first, organisationId: $organisationId) {
        edges {
          cursor
          node {
            created
            customMessage
            firstName
            group {
              id
              name
            }
            groupId
            id
            invitationEmailStatus
            invitedUserEmail: emailUserInvited
            invitedUserId
            lastName
            userType {
              id
              name
            }
          }
        }
        totalCount
      }
    }
  }
`;
