import { gql } from '@apollo/client';

export const CREATE_GROUP_INVITATION_MUTATION = gql`
  mutation createGroupInvitation($invitation: CreateGroupInvitationInput!) {
    invitation {
      createGroupInvitation: groupInvitation(invitation: $invitation) {
        created
        customMessage
        firstName
        group {
          id
          name
        }
        groupId
        id
        invitationEmailStatus
        emailUserInvited
        invitedUserId
        lastName
        phoneNumber
        userType {
          id
          name
        }
      }
    }
  }
`;
