import { gql } from '@apollo/client';

const GET_PAGINATED_GROUPS_FOR_USER_QUERY = gql`
  query getPaginatedGroupsForUser($userId: Int!) {
    group {
      getPaginatedGroupsForUser: getGroupsForUser(userId: $userId) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export { GET_PAGINATED_GROUPS_FOR_USER_QUERY };
