import { validateOnCompletedAndOnErrorOptions } from '@netfront/common-library';
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_CUSTOM_FIELDS_QUERY } from './useGetCustomFields.graphql';
import {
  IGetCustomFieldsQueryGraphQLResponse,
  IGetCustomFieldsQueryVariables,
  IHandleGetCustomFieldsParams,
  IUseGetCustomFields,
  IUseGetCustomFieldsOptions,
} from './useGetCustomFields.interfaces';

const useGetCustomFields = (options?: IUseGetCustomFieldsOptions): IUseGetCustomFields => {
  const { fetchPolicy = 'cache-and-network', onCompleted, onCompletedAsync, onError, onErrorAsync, query, token } = options ?? {};

  validateOnCompletedAndOnErrorOptions({
    onCompleted,
    onCompletedAsync,
    onError,
    onErrorAsync,
  });

  const [executeGetCustomFields, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetCustomFieldsQueryGraphQLResponse,
    IGetCustomFieldsQueryVariables
  >({
    options: {
      fetchPolicy,
      onCompleted: ({ customField: { getCustomFields: customFields } }) => {
        if (onCompleted) {
          onCompleted({
            customFields,
          });
        }

        if (onCompletedAsync) {
          void onCompletedAsync({
            customFields,
          });
        }
      },
      onError: (error) => {
        if (onError) {
          onError(error);
        }

        if (onErrorAsync) {
          void onErrorAsync(error);
        }
      },
    },
    query: query ?? GET_CUSTOM_FIELDS_QUERY,
    token,
  });

  const handleGetCustomFields = async ({ projectId, scope }: IHandleGetCustomFieldsParams) => {
    await executeGetCustomFields({
      variables: {
        projectId,
        scope,
      },
    });
  };

  return {
    handleGetCustomFields,
    isLoading,
  };
};

export { useGetCustomFields };
