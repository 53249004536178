import { useRef } from 'react';

import { useGetProduct } from '@netfront/gelada-identity-library';
import { useEnableEkardoProject, useEnableProduct, useEnableProject, useToast } from 'hooks';

import {
  IActivateProjectDetails,
  IHandleActivateProjectParams,
  IUseActivateProject,
  IUseActivateProjectProps,
} from './useActivateProject.interfaces';

const useActivateProject = ({ onCompleted }: IUseActivateProjectProps): IUseActivateProject => {
  const { getProductApi } = useGetProduct();
  const { handleToastError } = useToast();

  const projectDetailsRef = useRef<{
    value: IActivateProjectDetails;
  }>({ value: {} as IActivateProjectDetails });

  const enableProjectOnCompleted = () => {
    onCompleted?.(true);
    projectDetailsRef.current.value = {} as IActivateProjectDetails;
  };

  const { handleEnableProject, isLoading: isEnableProjectLoading = false } = useEnableProject({
    onCompleted: enableProjectOnCompleted,
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    apiName: getProductApi(),
  });

  const { handleEnableProduct, isLoading: isEnableProductLoading = false } = useEnableProduct({
    onCompleted: () => {
      const { projectId, projectName } = projectDetailsRef.current.value;
      handleEnableProject({
        id: projectId,
        name: projectName,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleEnableEkardoProject, isLoading: isEnableEkardoProjectLoading = false } = useEnableEkardoProject({
    onCompleted: enableProjectOnCompleted,
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    apiName: getProductApi(),
  });

  const handleActivateProject = ({ productName, projectName, organisationId, projectId }: IHandleActivateProjectParams) => {
    projectDetailsRef.current.value = {
      organisationId,
      productName,
      projectId,
      projectName,
    };
    if (productName === 'EKARDO') {
      handleEnableEkardoProject({
        projectGuid: projectId,
      });

      return;
    }

    handleEnableProduct({
      name: projectName,
      organisationId: organisationId,
      product: productName,
      projectId: projectId,
    });
  };

  return {
    handleActivateProject,
    isLoading: isEnableEkardoProjectLoading || isEnableProductLoading || isEnableProjectLoading,
  };
};

export { useActivateProject };
