import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { TOGGLE_ELEVATE_GROUP_MEMBER } from './useToggleElevateGroupMember.graphql';
import {
  IToggleElevateGroupMemberMutationGraphQLResponse,
  IToggleElevateGroupMemberMutationVariables,
  IHandleToggleElevateGroupMemberParams,
  IUseToggleElevateGroupMemberOptions,
  IUseToggleElevateGroupMember,
} from './useToggleElevateGroupMember.interfaces';

const useToggleElevateGroupMember = (options?: IUseToggleElevateGroupMemberOptions): IUseToggleElevateGroupMember => {
  const { onCompleted, onError, token, mutation } = options ?? ({} as IUseToggleElevateGroupMemberOptions);


  const [executeToggleElevateGroupMember, { loading: isLoading }] = useLoggedGeladaMutation<
  IToggleElevateGroupMemberMutationGraphQLResponse,
  IToggleElevateGroupMemberMutationVariables
  >({
    mutation: mutation ?? TOGGLE_ELEVATE_GROUP_MEMBER,
    options: {
      onCompleted: ({ membership: { elevateGroupMember: isCompleted } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });


  const handleToggleElevateGroupMember = ({ permission, projectId, userId }: IHandleToggleElevateGroupMemberParams) => {
    void executeToggleElevateGroupMember({
      variables: {
        permission,
        projectId,
        userId,
      },
    });
  };

  return {
    handleToggleElevateGroupMember,
    isLoading,
  };
};

export { useToggleElevateGroupMember };
