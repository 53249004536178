import { ApolloQueryResult } from '@apollo/client';
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_PAGINATED_GROUP_INVITATIONS_QUERY } from './useGetPaginatedGroupInvitations.graphql';
import {
  IGetPaginatedGroupInvitationsQueryGraphQLResponse,
  IGetPaginatedGroupInvitationsQueryVariables,
  IHandleFetchMorePaginatedGroupInvitationsParams,
  IHandleGetPaginatedGroupInvitationsParams,
  IUseGetPaginatedGroupInvitations,
  IUseGetPaginatedGroupInvitationsOptions,
} from './useGetPaginatedGroupInvitations.interfaces';

const useGetPaginatedGroupInvitations = (options?: IUseGetPaginatedGroupInvitationsOptions): IUseGetPaginatedGroupInvitations => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPaginatedGroupInvitationsOptions);

  const [executeGetPaginatedGroupInvitations, { fetchMore, loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetPaginatedGroupInvitationsQueryGraphQLResponse,
    IGetPaginatedGroupInvitationsQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ invitation: { getPaginatedGroupInvitations: groupInvitationConnection } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          groupInvitationConnection,
        });
      },
      onError,
    },
    query: query ?? GET_PAGINATED_GROUP_INVITATIONS_QUERY,
    token,
  });

  const handleFetchMorePaginatedGroupInvitations = ({
    after,
    filter,
    first,
    groupId,
    projectId,
  }: IHandleFetchMorePaginatedGroupInvitationsParams): Promise<ApolloQueryResult<IGetPaginatedGroupInvitationsQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (
        previousQueryResult,
        {
          fetchMoreResult: {
            invitation: { getPaginatedGroupInvitations: newlyFetchedGroupConnection },
          },
        },
      ) => {
        const { edges: newlyFetchedEdges } = newlyFetchedGroupConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          invitation: { getPaginatedGroupInvitations: previouslyFetchedGroupConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedGroupConnection;

        return {
          ...previousQueryResult,
          invitation: {
            ...previouslyFetchedGroupConnection,
            getPaginatedGroupInvitations: {
              ...newlyFetchedGroupConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        filter,
        first,
        groupId,
        projectId,
      },
    });
  };

  const handleGetPaginatedGroupInvitations = async ({
    after,
    filter,
    first,
    groupId,
    projectId,
  }: IHandleGetPaginatedGroupInvitationsParams) => {
    await executeGetPaginatedGroupInvitations({
      variables: {
        after,
        filter,
        first,
        groupId,
        projectId,
      },
    });
  };

  return {
    handleFetchMorePaginatedGroupInvitations,
    handleGetPaginatedGroupInvitations,
    isLoading,
  };
};

export { useGetPaginatedGroupInvitations };
