import { gql } from '@apollo/client';

export const GET_PAGINATED_GROUPS_MUTATION = gql`
  query getPaginatedGroups(
    $after: String
    $createdFrom: Date
    $createdTo: Date
    $filter: String
    $first: Int
    $maxUsers: Int
    $minUsers: Int
    $projectId: String!
    $shouldIncludeUserType: Boolean!
    $shouldIncludeAddress: Boolean!
    $shouldIncludeSmartCodes: Boolean!
    $shouldIncludeUnit: Boolean!
    $status: [EGroupStatus]
  ) {
    group {
      getPaginatedGroups(
        after: $after
        createdFrom: $createdFrom
        createdTo: $createdTo
        filter: $filter
        first: $first
        maxUsers: $maxUsers
        minUsers: $minUsers
        projectId: $projectId
        status: $status
      ) {
        edges {
          cursor
          node {
            userCount
            id
            name
            code
            created
            address @include(if: $shouldIncludeAddress) {
              line1
              line2
              city
              state
              postcode: postCode
            }
            smartCodes @include(if: $shouldIncludeSmartCodes) {
              code
              smartCodeId
              userType @include(if: $shouldIncludeUserType) {
                name
              }
            }
            groupTypeId
            status
            userCount
            website
            phoneNumber
            unit @include(if: $shouldIncludeUnit) {
              id
              groupId
              name
            }
          }
        }
        totalCount
      }
    }
  }
`;
