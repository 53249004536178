import { gql } from '@apollo/client';

export const GET_PAGINATED_GROUP_INVITATIONS_QUERY = gql`
  query getPaginatedGroupInvitations(
    $after: String
    $email: String
    $filter: String
    $first: Int
    $firstName: String
    $groupId: Int
    $lastName: String
    $projectId: String!
  ) {
    invitation {
      getPaginatedGroupInvitations: listProjectAndGroupInvitations(
        after: $after
        email: $email
        filter: $filter
        first: $first
        firstname: $firstName
        groupId: $groupId
        lastname: $lastName
        projectId: $projectId
      ) {
        edges {
          cursor
          node {
            created
            customMessage
            firstName
            group {
              id
              name
            }
            groupId
            id
            type
            invitationEmailStatus
            emailUserInvited
            invitedUserId
            phoneNumber
            lastName
            userType {
              id
              name
            }
          }
        }
        totalCount
      }
    }
  }
`;
