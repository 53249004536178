import { gql } from '@apollo/client';

const ENABLE_EKARDO_PROJECT_MUTATION = gql`
  mutation enableProject($projectGuid: String!) {
    project {
      enableProject(projectGuid: $projectGuid) {
        id
      }
    }
  }
`;

export { ENABLE_EKARDO_PROJECT_MUTATION };
