import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_USER_PERMISSION_FOR_GROUP } from './useGetUserPermissionForGroup.graphql';
import {
  IGetUserPermissionForGroupQueryGraphQLResponse,
  IGetUserPermissionForGroupQueryVariables,
  IHandleGetUserPermissionForGroupParams,
  IUseGetUserPermissionForGroup,
  IUseGetUserPermissionForGroupOptions,
} from './useGetUserPermissionForGroup.interfaces';

const useGetUserPermissionForGroup = (options?: IUseGetUserPermissionForGroupOptions): IUseGetUserPermissionForGroup => {
  const { fetchPolicy, onCompleted, onError } = options ?? ({} as IUseGetUserPermissionForGroupOptions);

  const [executeGetUserPermissionForGroup, { loading: isLoading }] = useLoggedGeladaLazyQuery<IGetUserPermissionForGroupQueryGraphQLResponse, IGetUserPermissionForGroupQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        const {
          membership: { getProjectMembershipForUser: membership },
        } = data;

        if (!onCompleted) {
          return;
        }

        onCompleted({
          membership,
        });
      },
      onError,
    },
    query: GET_USER_PERMISSION_FOR_GROUP,
  });

  const handleGetUserPermissionForGroup = async ({ projectId, userId }: IHandleGetUserPermissionForGroupParams) => {
    await executeGetUserPermissionForGroup({
      variables: {
        projectId,
        userId,
      },
    });
  };

  return {
    handleGetUserPermissionForGroup,
    isLoading,
  };
};

export { useGetUserPermissionForGroup };
