import { gql } from '@apollo/client';

const CREATE_GELADA_PROJECT_MUTATION = gql`
  mutation createGeladaProject(
    $isCustomBuild: Boolean!
    $name: String!
    $organisationId: Int!
    $description: String
    $key: String!
    $services: [ENetfrontService]!
    $logo: CreateAssetBaseInputType
  ) {
    project {
      createGeladaProject: createProject(
        project: {
          isCustomBuild: $isCustomBuild
          name: $name
          organisationId: $organisationId
          key: $key
          logo: $logo
          description: $description
          services: $services
        }
      ) {
        logo {
          presignedUrl
          presignedPutUrl
        }
        id
        name
        organisationId
        services
        projectAdministrators {
          firstname
          lastname
        }
        isFavourite
      }
    }
  }
`;

export { CREATE_GELADA_PROJECT_MUTATION };

