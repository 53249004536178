import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { ANSWER_CUSTOM_FIELDS_MUTATION } from './useAnswerCustomFields.graphql';
import {
  IAnswerCustomFieldsMutationGraphQLResponse,
  IAnswerCustomFieldsMutationVariables,
  IHandleAnswerCustomFieldsParams,
  IUseAnswerCustomFields,
  IUseAnswerCustomFieldsOptions,
} from './useAnswerCustomFields.interfaces';

const useAnswerCustomFields = (options?: IUseAnswerCustomFieldsOptions): IUseAnswerCustomFields => {
  const { onCompleted, onError, mutation, token } = options ?? ({} as IUseAnswerCustomFieldsOptions);

  const [executeAnswerCustomFields, { loading: isLoading }] = useLoggedGeladaMutation<
    IAnswerCustomFieldsMutationGraphQLResponse,
    IAnswerCustomFieldsMutationVariables
  >({
    mutation: mutation ?? ANSWER_CUSTOM_FIELDS_MUTATION,
    options: {
      onCompleted: ({ customFieldResponse: { answerCustomFields: isCompleted } }) => {
        if (!onCompleted) {
          return;
        }

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleAnswerCustomFields = async ({ projectId, responses, userId }: IHandleAnswerCustomFieldsParams) => {
    await executeAnswerCustomFields({
      variables: {
        projectId,
        responses,
        userId,
      },
    });
  };

  return {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    handleAnswerCustomFields,
    isLoading,
  };
};

export { useAnswerCustomFields };
