import { gql } from '@apollo/client';

const GET_GELADA_PROJECT_BY_DOMAIN_QUERY = gql`
  query getGeladaProjectByDomain($domain: String!) {
    getGeladaProjectByDomain: getByDomain(domain: $domain) {
      id
      isCustomBuild
      name
      domain {
        redirectAfterLogin
        value
        website
      }
      organisation {
        key
      }
      logo {
        contentType
        s3Key
      }
    }
  }
`;

export { GET_GELADA_PROJECT_BY_DOMAIN_QUERY };
