// TODO: Move this to @netfront/gelada-identity-library

import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { BULK_DELETE_INVITATION_MUTATION } from './useBulkDeleteInvitations.graphql';
import {
  IBulkDeleteInvitationsMutationGraphQLResponse,
  IBulkDeleteInvitationsMutationVariables,
  IHandleBulkDeleteInvitationsParams,
  IUseBulkDeleteInvitationsOptions,
  IUseBulkDeleteInvitations,
} from './useBulkDeleteInvitations.interfaces';

const useBulkDeleteInvitations = (options?: IUseBulkDeleteInvitationsOptions): IUseBulkDeleteInvitations => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseBulkDeleteInvitationsOptions);

  const [executeBulkDeleteInvitations, { loading: isLoading }] = useLoggedGeladaMutation<
    IBulkDeleteInvitationsMutationGraphQLResponse,
    IBulkDeleteInvitationsMutationVariables
  >({
    mutation: mutation ?? BULK_DELETE_INVITATION_MUTATION,
    options: {
      onCompleted: ({ invitation: { deleteInvitations: isCompleted } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleBulkDeleteInvitations = async ({ invitationIds }: IHandleBulkDeleteInvitationsParams) => {
    await executeBulkDeleteInvitations({
      variables: {
        invitationIds,
      },
    });
  };

  return {
    handleBulkDeleteInvitations,
    isLoading,
  };
};

export { useBulkDeleteInvitations };
