import { useEffect, useState } from 'react';

import { getParsedQuery } from '@netfront/common-library';
import { useRouter } from 'next/router';

import { IUseCodeQuerystringValidation } from './useCodeQuerystringValidation.interfaces';

import { ERROR_MESSAGES } from '../../constants';

const useCodeQuerystringValidation = (): IUseCodeQuerystringValidation => {
  const { codeIsRequired } = ERROR_MESSAGES;

  const { asPath } = useRouter();
  const [ fullUrl, queryString] = asPath.split('?');

  const { code: codeQuerystringParam } = getParsedQuery(queryString || fullUrl);

  const [code, setCode] = useState<string>();

  useEffect(() => {
    if (!codeQuerystringParam) {
      throw new Error(codeIsRequired);
    }

    setCode(String(codeQuerystringParam));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeQuerystringParam]);

  return {
    code,
  };
};

export { useCodeQuerystringValidation };
