import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { CREATE_NOTE } from './useCreateNote.graphql';
import {
  IUseCreateNote,
  ICreateNoteMutationGraphQLResponse,
  IUseCreateNoteOptions,
  ICreateNoteVariables,
  IHandleCreateNoteParams,
} from './useCreateNote.interfaces';

const useCreateNote = (options?: IUseCreateNoteOptions): IUseCreateNote => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseCreateNoteOptions);

  const [executeCreateNote, { loading: isLoading }] = useLoggedGeladaMutation<
  ICreateNoteMutationGraphQLResponse,
  ICreateNoteVariables
  >({
    mutation: mutation ?? CREATE_NOTE,
    options: {
      onCompleted: ({
        note: {
          addUserNote: note,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          note,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleCreateNote = async ({
    attachments,
    groupId,
    message,
    subject,
    userId
  }: IHandleCreateNoteParams) => {
    await executeCreateNote({
      variables: {
        attachments,
        groupId,
        message,
        subject,
        userId
      },
    });
  };

  return {
    handleCreateNote,
    isLoading,
  };
};

export { useCreateNote };
