import { usePublicGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_INVITATION } from './useGetInvitation.graphql';
import {
  IGetInvitationQueryGraphQLResponse,
  IGetInvitationQueryVariables,
  IHandleGetInvitationParams,
  IUseGetInvitation,
  IUseGetInvitationOptions,
} from './useGetInvitation.interfaces';

const useGetInvitation = (options?: IUseGetInvitationOptions): IUseGetInvitation => {
  const { fetchPolicy, onCompleted, onError } = options ?? ({} as IUseGetInvitationOptions);

  const [executeGetInvitation, { loading: isLoading }] = usePublicGeladaLazyQuery<
    IGetInvitationQueryGraphQLResponse,
    IGetInvitationQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ getInvitation }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          invitation: getInvitation,
        });
      },
      onError,
    },
    query: GET_INVITATION,
  });

  const handleGetInvitation = async ({ token }: IHandleGetInvitationParams) => {
    await executeGetInvitation({
      variables: {
        token,
      },
    });
  };

  return {
    handleGetInvitation,
    isLoading,
  };
};

export { useGetInvitation };
