// TODO: Move this to @netfront/gelada-identity-library

import { validateOnCompletedAndOnErrorOptions } from '@netfront/common-library';
import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';


import { DELETE_GELADA_ORGANISATION_MUTATION } from './useDeleteGeladaOrganisation.graphql';
import {
  IDeleteGeladaOrganisationMutationGraphQLResponse,
  IDeleteGeladaOrganisationMutationVariables,
  IHandleDeleteGeladaOrganisationParams,
  IUseDeleteGeladaOrganisationOptions,
  IUseDeleteGeladaOrganisation,
} from './useDeleteGeladaOrganisation.interfaces';

const useDeleteGeladaOrganisation = (options?: IUseDeleteGeladaOrganisationOptions): IUseDeleteGeladaOrganisation => {
  const { mutation, onCompleted, onCompletedAsync, onError, onErrorAsync, product, token } = options ?? {};

  validateOnCompletedAndOnErrorOptions({
    onCompleted,
    onCompletedAsync,
    onError,
    onErrorAsync,
  });

  const [executeDeleteGeladaOrganisation, { loading: isLoading }] = useLoggedGeladaMutation<
  IDeleteGeladaOrganisationMutationGraphQLResponse,
  IDeleteGeladaOrganisationMutationVariables
  >({
    mutation: mutation ?? DELETE_GELADA_ORGANISATION_MUTATION,
    options: {
      onCompleted: ({ organisation: { deleteGeladaOrganisation: isCompleted } }) => {
        if (onCompleted) {
          onCompleted({
            isCompleted,
          });
        }

        if (onCompletedAsync) {
          void onCompletedAsync({
            isCompleted,
          });
        }
      },
      onError: (error) => {
        if (onError) {
          onError(error);
        }

        if (onErrorAsync) {
          void onErrorAsync(error);
        }
      },
    },
    product,
    token,
  });

  const handleDeleteGeladaOrganisation = async ({ organisationKey }: IHandleDeleteGeladaOrganisationParams) => {
    await executeDeleteGeladaOrganisation({
      variables: {
        organisationKey,
      },
    });
  };

  return {
    handleDeleteGeladaOrganisation,
    isLoading,
  };
};

export { useDeleteGeladaOrganisation };
