import { gql } from '@apollo/client';

export const UPDATE_GROUP_MEMBERSHIP = gql`
  mutation updateGroupMembership($request: UpdateGroupMembershipInputType!) {
    membership {
      updateGroupMembership(request: $request) {
        id
      }
    }
  }
`;
