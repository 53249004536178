import { gql } from '@apollo/client';

export const CREATE_CUSTOM_FIELD_DATE = gql`
  mutation createCustomFieldDate(
    $projectId: String!,
    $isRequired: Boolean!,
    $name: String!,
    $scope: ECustomFieldScope!,
    $description: String,
    $rangeFrom: Date,
    $rangeTo: Date
  ) {
    customField {
      addCustomFieldDate(
        projectId: $projectId,
        isRequired: $isRequired, 
        name: $name,
        scope: $scope,
        description: $description,
        rangeFrom: $rangeFrom,
        rangeTo: $rangeTo
      ) {
        id
      }
    }
  }
`;
