import { gql } from '@apollo/client';

const COMMIT_EMAIL_OR_PHONE_NUMBER_CHANGE_MUTATION = gql`
  mutation commitEmailOrPhoneNumberChange($token: String!) {
    user {
      commitEmailOrPhoneNumberChange: commitChange(token: $token)
    }
  }
`;

export { COMMIT_EMAIL_OR_PHONE_NUMBER_CHANGE_MUTATION };
