import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { CREATE_CUSTOM_FIELD_NUMBER } from './useCreateCustomFieldNumber.graphql';
import {
  IUseCreateCustomFieldNumber,
  ICreateCustomFieldNumberMutationGraphQLResponse,
  IUseCreateCustomFieldNumberOptions,
  ICreateCustomFieldNumberMutationVariables,
  IHandleCreateCustomFieldNumberParams,
} from './useCreateCustomFieldNumber.interfaces';

const useCreateCustomFieldNumber = (options?: IUseCreateCustomFieldNumberOptions): IUseCreateCustomFieldNumber => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseCreateCustomFieldNumberOptions);

  const [executeCreateGroupInvitation, { loading: isLoading }] = useLoggedGeladaMutation<
    ICreateCustomFieldNumberMutationGraphQLResponse,
    ICreateCustomFieldNumberMutationVariables
  >({
    mutation: mutation ?? CREATE_CUSTOM_FIELD_NUMBER,
    options: {
      onCompleted: ({
        customField: {
          addCustomFieldNumber: customField,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          customField,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleCreateCustomFieldNumber = async ({
    description,
    isRequired,
    name,
    projectId,
    maxNumber,
    minNumber,
    scope,
  }: IHandleCreateCustomFieldNumberParams) => {
    await executeCreateGroupInvitation({
      variables: {
        description,
        isRequired,
        name,
        projectId,
        maxNumber,
        minNumber,
        scope,
      },
    });
  };

  return {
    handleCreateCustomFieldNumber,
    isLoading,
  };
};

export { useCreateCustomFieldNumber };
