import { ApolloQueryResult } from '@apollo/client';
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_PAGINATED_USER_TYPES } from './useGetPaginatedUserTypes.graphql';
import {
  IGetPaginatedUserTypesQueryGraphQLResponse,
  IGetPaginatedUserTypesQueryVariables,
  IHandleFetchMorePaginatedUserTypesParams,
  IHandleGetPaginatedUserTypesParams,
  IUseGetPaginatedUserTypes,
  IUseGetPaginatedUserTypesOptions,
} from './useGetPaginatedUserTypes.interfaces';

const useGetPaginatedUserTypes = (options?: IUseGetPaginatedUserTypesOptions): IUseGetPaginatedUserTypes => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPaginatedUserTypesOptions);

  const [executeGetPaginatedUserTypes, { fetchMore, loading: isLoading }] = useLoggedGeladaLazyQuery<
  IGetPaginatedUserTypesQueryGraphQLResponse,
  IGetPaginatedUserTypesQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ userType: { getUserTypes: userTypesConnection } }) => {
        if (!onCompleted) {
          return;
        }

        void onCompleted({
          userTypesConnection,
        });
      },
      onError,
    },
    query: query ?? GET_PAGINATED_USER_TYPES,
    token,
  });

  const handleFetchMorePaginatedUserTypes = ({
    after,
    projectId,
    name,
    first
  }: IHandleFetchMorePaginatedUserTypesParams): Promise<ApolloQueryResult<IGetPaginatedUserTypesQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          userType: { getUserTypes: newlyFetchedMembershipConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedMembershipConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          userType: { getUserTypes: previouslyFetchedMembershipConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedMembershipConnection;

        return {
          ...previousQueryResult,
          userType: {
            ...previouslyFetchedMembershipConnection,
            getUserTypes: {
              ...newlyFetchedMembershipConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        projectId,
        name,
        first
      },
    });
  };

  const handleGetPaginatedUserTypes = async ({ after, projectId, name , first}: IHandleGetPaginatedUserTypesParams) => {
    await executeGetPaginatedUserTypes({
      variables: {
        after,
        projectId,
        name,
        first
      },
    });
  };

  return {
    handleFetchMorePaginatedUserTypes,
    handleGetPaginatedUserTypes,
    isLoading,
  };
};

export { useGetPaginatedUserTypes };
