import { gql } from '@apollo/client';

const REGISTER_USER_EMAIL = gql`
  mutation registerUserEmail($request: LightRegistrationUserInputType!) {
    lightRegistration(request: $request)
  }
`;

export { REGISTER_USER_EMAIL };

