import { gql } from '@apollo/client';

const GET_PAGINATED_USER_TYPES = gql`
  query getPaginatedUserTypes(
    $after: String
    $name: String
    $first: Int
    $projectId: String!
    $status: EUserTypeStatus
  ) {
    userType {
      getUserTypes(
        after: $after
        name: $name
        first: $first
        projectId: $projectId
        status: $status
      ) {
        edges {
          cursor
          node {
            id
            name
            status
            created
            userCount
          }
        }
        totalCount
      }
    }
  }
`;

export { GET_PAGINATED_USER_TYPES };
