/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloCache, DefaultContext, MutationTuple, OperationVariables } from '@apollo/client';
import { getApolloLink, useApolloMutation, useCookie } from '@netfront/common-library';
import { useGetProduct } from '@netfront/gelada-identity-library';

import { IUseCustomMutationOptions } from './useCustomMutation.interfaces';

const useCustomMutation = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>({
  apolloLink,
  mutation,
  options,
  product,
  token,
  apiName,
}: IUseCustomMutationOptions<TData, TVariables, TContext>): MutationTuple<TData, TVariables, TContext, TCache> => {
  const { getAccessTokenCookie, getTemporaryTokenCookie } = useCookie();
  const { getProduct } = useGetProduct();

  const accessToken = getAccessTokenCookie();
  const temporaryToken = getTemporaryTokenCookie();
  const definedProduct = product ?? getProduct();

  const authenticationLink = getApolloLink({
    apiName,
    product: definedProduct,
    token: token ?? accessToken ?? temporaryToken,
  });

  return useApolloMutation<TData, TVariables, TContext, TCache>({
    apiName,
    apolloLink: apolloLink ?? authenticationLink,
    mutation,
    options,
  });
};

export { useCustomMutation };
