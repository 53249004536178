import { useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { useToast } from '@netfront/ekardo-content-library';

import { FormFieldProps } from '@netfront/ui-library';
import { ICustomFieldItem, useAddOptionIntoMultiResponse, useCreateCustomFieldDate, useCreateCustomFieldMultiResponse, useCreateCustomFieldNumber, useCreateCustomFieldText, useUpdateCustomField } from 'hooks';
import { format } from 'date-fns';

interface IresponseOption {
  id: number;
  isDefault: boolean;
  value: string;
}

const useUpsertCustomField = ({ onCreate, onUpdate }: { onCreate: () => void; onUpdate: (returnedCustomField: ICustomFieldItem) => void }) => {
  const responseOptions = useRef<{value: IresponseOption[]}>({value: []});
  const chainedRequestsCompletedCountRef = useRef({ value: 0 });
  const chainedRequestsCountRef = useRef({ value: 0 });

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleAddResponseOptionOnCompleted = () => {
    chainedRequestsCompletedCountRef.current.value += 1;

    if (chainedRequestsCompletedCountRef.current.value === chainedRequestsCountRef.current.value) {
      onCreate();
      chainedRequestsCompletedCountRef.current.value = 0;
      chainedRequestsCountRef.current.value = 0;
    }
  };


  const { handleAddOptionIntoMultiResponse, isLoading: isAddOptionIntoMultiResponseLoading = false } = useAddOptionIntoMultiResponse({
    onCompleted: handleAddResponseOptionOnCompleted,
    onError: handleGetError,
  });
  
  const { handleCreateCustomFieldText, isLoading: isCreateCustomFieldTextLoading = false } = useCreateCustomFieldText({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Custom field successfully created',
      });

      onCreate();

    },
    onError: handleGetError,
  });
  
  const { handleCreateCustomFieldDate, isLoading: isCreateCustomFieldDateLoading = false } = useCreateCustomFieldDate({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Custom field successfully created',
      });

      onCreate();

    },
    onError: handleGetError,
  });

  const { handleCreateCustomFieldNumber, isLoading: isCreateCustomFieldNumberLoading = false } = useCreateCustomFieldNumber({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Custom field successfully created',
      });

      onCreate();

    },
    onError: handleGetError,
  });
  
  const { handleCreateCustomFieldMultiResponse, isLoading: isCreateCustomFieldMultiResponseLoading = false } = useCreateCustomFieldMultiResponse({
    onCompleted: ({ customField: returnedCustomField }) => {

      const { id } = returnedCustomField;


      responseOptions.current.value.forEach((responseOption) => handleAddOptionIntoMultiResponse({ customFieldId: id, option: responseOption.value }));

    },
    onError: handleGetError,
  });


  const { handleUpdateCustomField, isLoading: isUpdateCustomFieldLoading = false } = useUpdateCustomField({
    onCompleted: ({ customField: returnedCustomField }) => {
      handleToastSuccess({
        message: 'Custom field successfully updated',
      });
      onUpdate(returnedCustomField);
    },
    onError: handleGetError,
  });

  const handleUpsertCustomField = ({ projectId, customFieldId, variables }: {projectId: string; customFieldId?: number; variables: FormFieldProps; }) => {
    
    const {
      description,
      isRequired,
      name,
      scope,
      type,
      regex = '',
      rangeFrom,
      rangeTo,
      maxNumber,
      minNumber,
      availableOptions = [],
    } = variables;

    responseOptions.current.value = availableOptions;
    chainedRequestsCountRef.current.value = availableOptions.length;

    const sharedVariables = {
      description,
      isRequired,
      name,
      scope,
    };

    if (!customFieldId) {
      if (type === 'text') {
        handleCreateCustomFieldText({
          projectId,
          regex,
          ...sharedVariables
        });
      }

      if (type === 'date') {
        handleCreateCustomFieldDate({
          projectId,
          rangeFrom: format(rangeFrom, 'yyyy-MM-dd'),
          rangeTo: format(rangeFrom, 'yyyy-MM-dd'),
          ...sharedVariables
        });
      }
      
      if (type === 'number') {
        handleCreateCustomFieldNumber({
          projectId,
          maxNumber: Number(maxNumber),
          minNumber: Number(minNumber),
          ...sharedVariables
        });
      }
      
      if (['radio', 'checkbox', 'dropdown'].includes(type)) {

        const typeMap: { [key: string]: 'CHECKBOX' | 'DROP_DOWN_LIST' | 'RADIO'} = {
          checkbox: 'CHECKBOX',
          dropdown: 'DROP_DOWN_LIST',
          radio: 'RADIO',
        };

        handleCreateCustomFieldMultiResponse({
          projectId,
          type: typeMap[String(type)],
          ...sharedVariables
        });
      }
    } else {

      handleUpdateCustomField({
        customFieldId,
        ...sharedVariables,
      });
    }
  };

  return {
    isLoading: 
      isAddOptionIntoMultiResponseLoading || 
      isUpdateCustomFieldLoading || 
      isCreateCustomFieldMultiResponseLoading || 
      isCreateCustomFieldNumberLoading || 
      isCreateCustomFieldDateLoading || 
      isCreateCustomFieldTextLoading,
    handleUpsertCustomField,
  };
};
export { useUpsertCustomField };
