import { gql } from '@apollo/client';

const GET_USER_PERMISSION_FOR_GROUP = gql`
  query getProjectMembershipForUser($projectId: String!, $userId: Int!) {
    membership {
      getProjectMembershipForUser(projectId: $projectId, userId: $userId) {
        id
        isProjectAdmin
        permission: permision
      }
    }
  }
`;

export { GET_USER_PERMISSION_FOR_GROUP };
