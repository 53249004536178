import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { DELETE_CUSTOM_FIELD } from './useDeleteCustomField.graphql';
import {
  IUseDeleteCustomField,
  IDeleteCustomFieldMutationGraphQLResponse,
  IUseDeleteCustomFieldOptions,
  IDeleteCustomFieldMutationVariables,
  IHandleDeleteCustomFieldParams,
} from './useDeleteCustomField.interfaces';

const useDeleteCustomField = (options?: IUseDeleteCustomFieldOptions): IUseDeleteCustomField => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseDeleteCustomFieldOptions);

  const [executeCreateGroupInvitation, { loading: isLoading }] = useLoggedGeladaMutation<
    IDeleteCustomFieldMutationGraphQLResponse,
    IDeleteCustomFieldMutationVariables
  >({
    mutation: mutation ?? DELETE_CUSTOM_FIELD,
    options: {
      onCompleted: ({
        customField: {
          removeCustomField: isCompleted,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleDeleteCustomField = async ({
    customFieldId,
  }: IHandleDeleteCustomFieldParams) => {
    await executeCreateGroupInvitation({
      variables: {
        customFieldId,
      },
    });
  };

  return {
    handleDeleteCustomField,
    isLoading,
  };
};

export { useDeleteCustomField };
