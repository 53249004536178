import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { CREATE_CUSTOM_FIELD_TEXT } from './useCreateCustomFieldText.graphql';
import {
  IUseCreateCustomFieldText,
  ICreateCustomFieldTextMutationGraphQLResponse,
  IUseCreateCustomFieldTextOptions,
  ICreateCustomFieldTextMutationVariables,
  IHandleCreateCustomFieldTextParams,
} from './useCreateCustomFieldText.interfaces';

const useCreateCustomFieldText = (options?: IUseCreateCustomFieldTextOptions): IUseCreateCustomFieldText => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseCreateCustomFieldTextOptions);

  const [executeCreateGroupInvitation, { loading: isLoading }] = useLoggedGeladaMutation<
    ICreateCustomFieldTextMutationGraphQLResponse,
    ICreateCustomFieldTextMutationVariables
  >({
    mutation: mutation ?? CREATE_CUSTOM_FIELD_TEXT,
    options: {
      onCompleted: ({
        customField: {
          addCustomFieldText: customField,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          customField,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleCreateCustomFieldText = async ({
    description,
    isRequired,
    name,
    projectId,
    regex,
    scope,
  }: IHandleCreateCustomFieldTextParams) => {
    await executeCreateGroupInvitation({
      variables: {
        description,
        isRequired,
        name,
        projectId,
        regex,
        scope,
      },
    });
  };

  return {
    handleCreateCustomFieldText,
    isLoading,
  };
};

export { useCreateCustomFieldText };
