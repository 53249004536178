import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GROUP_INSIGHTS } from './useGetGroupInsightLogin.graphql';
import {
  IGetGroupInsightLoginQueryGraphQLResponse,
  IGetGroupInsightLoginQueryVariables,
  IHandleGetGroupInsightLoginParams,
  IUseGetGroupInsightLogin,
  IUseGetGroupInsightLoginOptions,
} from './useGetGroupInsightLogin.interfaces';

const useGetGroupInsightLogin = (options?: IUseGetGroupInsightLoginOptions): IUseGetGroupInsightLogin => {
  const { fetchPolicy, onCompleted, onError } = options ?? ({} as IUseGetGroupInsightLoginOptions);

  const [executeGetGroupInsightLogin, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetGroupInsightLoginQueryGraphQLResponse,
    IGetGroupInsightLoginQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        const {
          groupInsights: {
            logins: { userTypeLogins },
          },
        } = data;

        if (!onCompleted) {
          return;
        }

        onCompleted({
          values: userTypeLogins,
        });
      },
      onError,
    },
    query: GROUP_INSIGHTS,
  });

  const handleGetGroupInsightLogin = async ({ groupId, end, start }: IHandleGetGroupInsightLoginParams) => {
    await executeGetGroupInsightLogin({
      variables: {
        groupId,
        end,
        start,
      },
    });
  };

  return {
    handleGetGroupInsightLogin,
    isLoading,
  };
};

export { useGetGroupInsightLogin };
