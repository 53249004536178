import { NetfrontProductType } from '@netfront/common-library';

import { ProductPageConfig } from '../types';

const ACTIVE = 'ACTIVE';
const INACTIVE = 'INACTIVE';

const IS_REQUIRED = 'is required';

const INTERNAL_URLS =  Object.freeze({
  dashboard: '/dashboard',
  login: '/login',
  profile: '/profile',
});

const ERROR_MESSAGES = Object.freeze({
  codeIsRequired: `Code ${IS_REQUIRED}`,
  productIsRequired: `Product ${IS_REQUIRED}`,
  returnUrlIsRequired: `ReturnUrl ${IS_REQUIRED}`,
  returnUrlIsMissingWillRedirectToTheRoot: `ReturnUrl is missing, the user will be redirected to the host`,
  unknownProduct: 'Unknown product',
});

const PAGE_CONFIG_LOCAL: Record<NetfrontProductType, ProductPageConfig | null> = Object.freeze({
  KANZI: {
    host: 'http://localhost:6500',
  },
  EKARDO: {
    host: 'https://localhost:8080',
  },
  QUICKCODES: {
    host: 'https://localhost:8080',
  },
});

const PAGE_CONFIG_DEVELOPMENT: Record<NetfrontProductType, ProductPageConfig | null> = Object.freeze({
  KANZI: {
    host: 'https://d1yvvlhfc6y0u6.cloudfront.net',
  },
  EKARDO: {
    host: 'https://dev.ekardo.com',
  },
  QUICKCODES: {
    host: 'https://quickcodes.io',
  },
});

const PAGE_CONFIG_PRODUCTION: Record<NetfrontProductType, ProductPageConfig | null> = Object.freeze({
  KANZI: {
    host: 'https://kanzi.io',
  },
  EKARDO: {
    host: 'https://ekardo.com',
  },
  QUICKCODES: {
    host: 'https://quickcodes.io',
  },
});

const USER_NOT_ACTIVATED_MESSAGE = 'User not activated';

const MFA_CODE_REQUESTED = 'MFA_CODE_REQUESTED';

export { ACTIVE, ERROR_MESSAGES, INACTIVE, PAGE_CONFIG_DEVELOPMENT, PAGE_CONFIG_PRODUCTION, PAGE_CONFIG_LOCAL, USER_NOT_ACTIVATED_MESSAGE, MFA_CODE_REQUESTED, INTERNAL_URLS };
