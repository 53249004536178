import { gql } from '@apollo/client';

export const UPDATE_NOTE = gql`
  mutation UpdateNote($noteId: Int!, $subject: String!, $message: String!, $attachments: [CreateNoteAttachmentAssetInputType]) {
    note {
      editNote(noteId: $noteId, subject: $subject, message: $message, attachments: $attachments) {
        id
        message
        subject
        attachments {
          asset {
            s3Key
            presignedUrl
            presignedPutUrl
          }
          assetId
        }
      }
    }
  }
`;
