import { gql } from '@apollo/client';

const GET_PAGINATED_GROUP_MEMBERS = gql`
  query getPaginatedGroupMembers(
    $after: String
    $filter: String
    $first: Int
    $groupId: Int
    $projectId: String!
    $status: EUserStatus
    $shouldReturnUnassignedOnly: Boolean = false
  ) {
    membership {
      getPaginatedGroupMembers(
        after: $after
        filter: $filter
        first: $first
        groupId: $groupId
        projectId: $projectId
        status: $status
        returnOnlyNonAssigned: $shouldReturnUnassignedOnly
      ) {
        edges {
          cursor
          node {
            created
            group {
              name
            }
            groupId
            id @skip(if: $shouldReturnUnassignedOnly)
            isProjectAdmin
            permission: permision
            user {
              communityName
              created
              credential {
                email
                phoneNumber
                services
              }
              customFields {
                customField {
                  description
                  name
                }
              }
              firstName: firstname
              id
              lastName: lastname
              memberships {
                id
              }
              status
            }
            userId
            userType {
              id
              name
            }
          }
        }
        totalCount
      }
    }
  }
`;

export { GET_PAGINATED_GROUP_MEMBERS };
