// TODO: Move this to @netfront/gelada-identity-library

import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { REMOVE_USERS_FROM_GROUP_MUTATION } from './useRemoveUsersFromGroup.graphql';
import {
  IRemoveUsersFromGroupMutationGraphQLResponse,
  IRemoveUsersFromGroupMutationVariables,
  IHandleRemoveUsersFromGroupParams,
  IUseRemoveUsersFromGroupOptions,
  IUseRemoveUsersFromGroup,
} from './useRemoveUsersFromGroup.interfaces';

const useRemoveUsersFromGroup = (options?: IUseRemoveUsersFromGroupOptions): IUseRemoveUsersFromGroup => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseRemoveUsersFromGroupOptions);

  const [executeRemoveUsersFromGroup, { loading: isLoading }] = useLoggedGeladaMutation<
  IRemoveUsersFromGroupMutationGraphQLResponse,
  IRemoveUsersFromGroupMutationVariables
  >({
    mutation: mutation ?? REMOVE_USERS_FROM_GROUP_MUTATION,
    options: {
      onCompleted: ({ membership: { removeUsersFromGroup: isCompleted } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleRemoveUsersFromGroup = async ({ groupId, userIds }: IHandleRemoveUsersFromGroupParams) => {
    await executeRemoveUsersFromGroup({
      variables: {
        groupId,
        userIds,
      },
    });
  };

  return {
    handleRemoveUsersFromGroup,
    isLoading,
  };
};

export { useRemoveUsersFromGroup };
