import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { CREATE_GROUP_INVITATION_MUTATION } from './useCreateGroupInvitation.graphql';
import {
  ICreateGroupInvitationMutationGraphQLResponse,
  ICreateGroupInvitationMutationVariables,
  IHandleCreateGroupInvitationParams,
  IUseCreateGroupInvitationOptions,
  IUseCreateGroupInvitation,
} from './useCreateGroupInvitation.interfaces';

const useCreateGroupInvitation = (options?: IUseCreateGroupInvitationOptions): IUseCreateGroupInvitation => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseCreateGroupInvitationOptions);

  const [executeCreateGroupInvitation, { loading: isLoading }] = useLoggedGeladaMutation<
    ICreateGroupInvitationMutationGraphQLResponse,
    ICreateGroupInvitationMutationVariables
  >({
    mutation: mutation ?? CREATE_GROUP_INVITATION_MUTATION,
    options: {
      onCompleted: ({ invitation: { createGroupInvitation: invitation } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          invitation,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleCreateGroupInvitation = async ({
    aliasName,
    customFields,
    customMessage,
    firstName,
    groupId,
    invitedUserEmail,
    lastName,
    permission,
    phoneNumber,
    services,
    title,
    unitId,
    userTypeId,
  }: IHandleCreateGroupInvitationParams) => {
    await executeCreateGroupInvitation({
      variables: {
        invitation: {
          aliasName,
          customFields,
          customMessage,
          emailInvited: invitedUserEmail,
          firstName,
          groupId,
          lastName,
          permission,
          phoneNumber,
          services,
          title,
          unitId,
          userTypeId,
        },
      },
    });
  };

  return {
    handleCreateGroupInvitation,
    isLoading,
  };
};

export { useCreateGroupInvitation };
