import { validateOnCompletedAndOnErrorOptions } from '@netfront/common-library';
import { convertDBGeladaProject, useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { CREATE_GELADA_PROJECT_MUTATION } from './useCreateGeladaProject.graphql';
import {
  ICreateGeladaProjectMutationGraphQLResponse,
  ICreateGeladaProjectMutationVariables,
  IHandleCreateGeladaProjectParams,
  IUseCreateGeladaProjectOptions,
  IUseCreateGeladaProject,
} from './useCreateGeladaProject.interfaces';


const useCreateGeladaProject = (options?: IUseCreateGeladaProjectOptions): IUseCreateGeladaProject => {
  const { mutation, onCompleted, onCompletedAsync, onError, onErrorAsync, product, token } = options ?? {};

  validateOnCompletedAndOnErrorOptions({
    onCompleted,
    onCompletedAsync,
    onError,
    onErrorAsync,
  });

  const [executeCreateGeladaProject, { loading: isLoading }] = useLoggedGeladaMutation<
  ICreateGeladaProjectMutationGraphQLResponse,
  ICreateGeladaProjectMutationVariables
  >({
    mutation: mutation ?? CREATE_GELADA_PROJECT_MUTATION,
    options: {
      onCompleted: ({ project: { createGeladaProject: geladaProject } }) => {
        const project = convertDBGeladaProject(geladaProject);

        if (onCompleted) {
          onCompleted({
            project,
          });
        }

        if (onCompletedAsync) {
          void onCompletedAsync({
            project,
          });
        }
      },
      onError: (error) => {
        if (onError) {
          onError(error);
        }

        if (onErrorAsync) {
          void onErrorAsync(error);
        }
      },
    },
    product,
    token,
  });

  const handleCreateGeladaProject = async ({
    isCustomBuild,
    name,
    organisationId,
    logo,
    key,
    description,
    services,
    shouldIncludeProjectLogo = false,
  }: IHandleCreateGeladaProjectParams) => {
    await executeCreateGeladaProject({
      variables: {
        isCustomBuild,
        name,
        organisationId,
        key,
        logo,
        description,
        services,
        shouldIncludeProjectLogo,
      },
    });
  };

  return {
    handleCreateGeladaProject,
    isLoading,
  };
};

export { useCreateGeladaProject };
