// TODO: Move this to @netfront/gelada-identity-library

import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { UPDATE_PROJECT_MEMBERSHIP_PERMISSION_MUTATION } from './useUpdateProjectMembershipPermission.graphql';
import {
  IUpdateProjectMembershipPermissionMutationGraphQLResponse,
  IUpdateProjectMembershipPermissionMutationVariables,
  IHandleUpdateProjectMembershipPermissionParams,
  IUseUpdateProjectMembershipPermissionOptions,
  IUseUpdateProjectMembershipPermission,
} from './useUpdateProjectMembershipPermission.interfaces';

const useUpdateProjectMembershipPermission = (
  options?: IUseUpdateProjectMembershipPermissionOptions,
): IUseUpdateProjectMembershipPermission => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateProjectMembershipPermissionOptions);

  const [executeUpdateProjectMembershipPermission, { loading: isLoading }] = useLoggedGeladaMutation<
    IUpdateProjectMembershipPermissionMutationGraphQLResponse,
    IUpdateProjectMembershipPermissionMutationVariables
  >({
    mutation: mutation ?? UPDATE_PROJECT_MEMBERSHIP_PERMISSION_MUTATION,
    options: {
      onCompleted: ({ membership: { updateProjectMembershipPermission: isCompleted } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateProjectMembershipPermission = async ({ membershipId, permission }: IHandleUpdateProjectMembershipPermissionParams) => {
    await executeUpdateProjectMembershipPermission({
      variables: {
        membershipId,
        permission,
      },
    });
  };

  return {
    handleUpdateProjectMembershipPermission,
    isLoading,
  };
};

export { useUpdateProjectMembershipPermission };
